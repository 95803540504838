import React, { useEffect } from 'react';


import logo from './logo.svg';
import Home from './Pages/Home/Home'
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './Pages/About/About';
import CTA from './Components/Footer/CTA';
import Features from './Pages/Features/Features';
import AOS from 'aos';
import Blog from './Pages/Blog/Blog';
import BlogPost from './Pages/Blog/BlogPost';
import Terms from './Pages/Legal/Terms';

function App() {

    const location = useLocation();
    console.log("location: ", location)

    useEffect(() => {
      AOS.init({
        once: true,
        disable: 'phone',
        duration: 600,
        easing: 'ease-out-sine',
      });
    });
  
    useEffect(() => {
      document.querySelector('html').style.scrollBehavior = 'auto'
      window.scroll({ top: 0 })
      document.querySelector('html').style.scrollBehavior = ''
    }, [location.pathname]); // triggered on route change
  
    

  return (
    <div className="font-poppins">
            <Navbar/>
            <Routes>
                <Route path='/team' exact element={<About/>} />
                {/* <Route path='/features' exact element={<Features/>} /> */}
                <Route path='/blog' element={<Blog></Blog>}/>
                <Route path='/blog-post' element={<BlogPost/>}/>
                <Route path='/legal' exact element={<Terms/>}></Route>

                <Route path='/' element={<Home/>}/>


                

            </Routes>
            <CTA/>
            <Footer/>
      
    </div>
  );
}

export default App;
