import React from 'react'
import logo from '../../icons/desklamp_logo_purple.svg'
import youtube from '../../icons/social/youtube.svg'
import email from '../../icons/social/email.svg'

function Footer() {
  return (
    
    <footer class="pb-4 bg-white sm:pb-6">
        <hr class="mb-6 border-gray-200 sm:mx-auto lg:mb-8 hidden md:block "/>

        <div class="hidden md:flex md:justify-between px-40">
            <div class="mb-6 md:mb-0 align-middle justify-center flex	">
                <a href="/" class="flex items-center">
                    <img src={logo} class="mr-3 h-7" alt="Desklamp Logo"/>
                </a>

                {/* <div class="my-5 text-sm text-gray-500 ">
                    The new way to read.
                </div> */}

            </div>
            
            
            
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-brightDarkPurple uppercase">Product</h2>
                    <ul class="text-gray-600">
                        <li class="mb-4">
                        <a href="/" class="hover:underline">Download</a>
                        </li>
                        <li class="mb-4">
                        <a href="https://store.desklamp.io" class="hover:underline">JEE Notes</a>
                        </li>
                        <li>
                        <a href="https://feedback.desklamp.io" target="_blank" rel="noreferrer" class="hover:underline">Feature Requests</a>
                        </li>
                    </ul>
                </div>
            <div>
            <h2 class="mb-6 text-sm font-semibold text-brightDarkPurple uppercase">Company</h2>
            <ul class="text-gray-600">
            <li class="mb-4">
            <a href="/team" class="hover:underline ">Team</a>
            </li>
            <li class="mb-4">
            <a href="/team" class="hover:underline">Careers</a>
            </li>
            {/* <li>
            <a href="https://github.com/themesberg/flowbite" class="hover:underline ">Awards</a>
            </li> */}

            
            </ul>
            </div>
            <div>
            <h2 class="mb-6 text-sm font-semibold text-brightDarkPurple uppercase">Legal</h2>
            <ul class="text-gray-600">
            <li class="mb-4">
            <a href="/legal?page=privacy" class="hover:underline">Privacy Policy</a>
            </li>
            <li>
            <a href="/legal?page=terms" class="hover:underline">Terms of Service</a>
            </li>
            </ul>
            </div>
            </div>
        </div>

        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8"/>

        <div class="sm:flex sm:items-center sm:justify-center md:justify-between px-40">

        <span class="hidden md:block text-sm text-gray-500 sm:text-center">
            © 2022 <a href="/" class="hover:underline">Desklamp™ Software LLP</a>.
        </span>

        <div class="hidden lg:block text-sm text-gray-500">
                Made with <span className="text-brightPurple">&#9829; </span> in Bengaluru and Chennai.
            </div>
        <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a href="mailto:developers@desklamp.io" target="_blank" rel="noreferrer" class="text-gray-500 hover:text-gray-900 ">
                <img className="w-5 h-5" src={email}></img>
            </a>
            <a href="https://instagram.com/desklamp.io" target="_blank" rel="noreferrer" class="text-gray-500 hover:text-gray-900 ">
                <svg class="w-5 h-5 hover:fill-brightDarkPurple" fill="grey" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path></svg>
            </a>
            <a href="https://twitter.com/TeamDesklamp" target="_blank" rel="noreferrer" class="text-gray-500 hover:text-gray-900 ">
                <svg class="w-5 h-5 hover:fill-brightDarkPurple " fill="grey" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path></svg>
            </a>
            <a href="https://www.youtube.com/channel/UCH3T_AK6nFpnDNeLusXBrHg" target="_blank" rel="noreferrer" class="text-gray-500 hover:text-gray-900 ">
                <svg class="hover:fill-brightDarkPurple" fill="grey" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"/></svg>            </a>
            <a href="https://www.linkedin.com/company/desklamp-io" target="_blank" rel="noreferrer" class="text-gray-500 hover:text-gray-900 ">
                <svg class="w-6 h-6 hover:fill-brightDarkPurple" fill="grey" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">    <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"/></svg>            </a>
        </div>
        </div>
    </footer>

  )
}

export default Footer