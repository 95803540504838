import React from 'react';

import PageIllustration from './Partials/PageIllustration';
import BlogSingle from './Partials/BlogSingle';
import {postContent} from './Posts/Post1';

function BlogPost() {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

  
      {/*  Page content */}
      <main className="grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <BlogSingle 
            title="Introducing Desklamp: The New Way to Read"
            excerpt="Why are we called Desklamp? What's our journey been like so far? Read on to learn more!"
            author="Akash Anand"
            authorRole="Co-founder"
            postContent={postContent} />
   

      </main>


    </div>
  );
}

export default BlogPost;