import React from 'react'
import {people} from '../AboutData'

function Team() {
  return (
    //   <div>
    //       This is our team
    //   </div>
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 "> 
        <div className="py-2 pb-20 "> 
        {/* border-t border-gray-800 */}

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-4xl font-bold text-brightDarkPurple">Meet Our Team</h2>
            <p className="text-l text-gray-400">The brains behind your second brain.</p>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

                {
                    people.map((person, index)=>{
                        const {name, role, image, twitter, linkedin} = person
                        return <PersonCard dataAosDelay={`${100*index}`} name={name} role={role} image={image} twitter={twitter} linkedin={linkedin} />
                    })
                }
          </div>

        </div>
      </div>
    </section>
  )
}


const PersonCard = (props) =>{

    const {name, role, image, dataAosDelay, twitter, linkedin} = props

    return(
    <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay={dataAosDelay} data-aos-anchor="[data-aos-id-team]">
        <div className="flex flex-col items-center">
        <img className="rounded-full mb-4 h-36 w-36" src={image} width="120" height="120" alt="Team member 08" />
        <h4 className="text-xl font-medium mb-1 text-darkPurple">{name}</h4>
        <div className="text-gray-500 mb-1">{role}</div>
        <div className="flex items-center space-x-3">
            {
                props.twitter?
                <a
                href={props.twitter}
                target="_blank"
                rel="noreferrer"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                <svg viewBox="0 0 24 24" className='h-5 hover:fill-brightDarkPurple' fill="grey" >
                    <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
                </a>
                :null
                }
                {
                    props.linkedin?
            <a
                href={props.linkedin}
                target="_blank"
                rel="noreferrer"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
                <svg className='hover:fill-brightDarkPurple' fill="grey" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">    <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"/></svg>            </a>
            :null
            }
        
        
        </div>        </div>

        
    </div>
    )

}


export default Team