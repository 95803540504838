import React from 'react';

import PageIllustration from './Partials/PageIllustration';
import './Blog.css'
import BlogList from './Partials/BlogList';
// import Newsletter from '../partials/Newsletter';
// import Footer from '../partials/Footer';

function Blog() {
  return (
    <div className="flex flex-col overflow-hidden">

      {/*  Page content */}
      <main className="grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <BlogList />
        {/* <Newsletter /> */}
        {/* <div>
            {postContent}
        </div> */}

      </main>

    

    </div>
  );
}

export default Blog;