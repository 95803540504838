import pesu from '../../icons/clients/pesu.png'
import iitk from '../../icons/clients/iitk.png'
import iitm from '../../icons/clients/iitm.png'

export const testimonials = [
    {
      quote: '“ Studying used to be so boring before Desklamp. I’ve started reading so much more now!  “',
      name: 'Sanjana K.',
      role: 'Electrical Engineering Student at',
      team: 'IIT Kanpur',
      link: '#0',
      icon: iitk
    },
    {
      quote: '“ Desklamp is great for project maintenance and literature surveys.“',
      name: 'Dr. Satya Sheshadri S',
      role: 'Assistant Professor at',
      team: 'IIT Madras',
      link: '#0',
      icon: iitm,
    },
    {
      quote: '“ I have fallen in love with Desklamp.“',
      name: 'Sai Pranav',
      role: 'Mechanical Engineering Student at',
      team: 'PES University',
      link: '#0',
      icon: pesu
    }
  ]