import React, { Component } from 'react'
import TestimonialsArrows from '../../Components/Testimonials/TestimonialsArrows'
import Hero from './Hero'
import FeaturesZigzag from './Partials/FeaturesZigzag'
import RecognitionBanner from './RecognitionBanner'
import {testimonials} from './HomeData'
import FeaturesBlocks from './Partials/FeaturesBlocks'
import HeroHome from './HeroHome'
import StoreBanner from '../../Components/StoreBanner/StoreBanner'

export class Home extends Component {
  render() {
    return (
        <div>
            <HeroHome />
            <StoreBanner />
            <FeaturesZigzag />
            <RecognitionBanner />

            <FeaturesBlocks/>
            <TestimonialsArrows items = {testimonials} />

        </div>
        

        
    )
  }
}

export default Home