import React from 'react'
import group_study from '../../icons/sharing.png'

function StoreBanner() {
  return (
    <div className=' text-center mt-10	'>
        {/* <div className="inline-block px-3 text-md font-semibold tracking-wider text-grey text-center	  rounded-full">
            Awarded By
        </div> */}
            <div className="lg:block bg-indigo-50  w-6/6 rounded-lg max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-4 lg:px-8 lg:flex lg:items-center lg:justify-between items-center">
                
                <div className='text-lg mb-3 sm:mb-0 font-bold sm:block sm:text-xxl text-brightDarkPurple'>Preparing for JEE?</div>
                <div className='text-md mb-5 sm:mb-0 sm:block lg:mt-0 lg:mb-0 text-brightDarkPurple'>
                    {/* <img className='w-12 md:hidden' src={group_study}></img> */}
                    Get toppers' notes for <span  className='font-bold'>FREE</span> <span className='hidden lg:inline'>and peek into their prep using the power of Desklamp.</span>
                </div>
                <div className=" text-md cursor-pointer md:block px-2 border border-brightPurple bg-brightPurple rounded-md py-2 hover:bg-transparent transition-colors hover:text-brightPurple	 text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <a href="https://store.desklamp.io" target="_blank" rel="noreferrer">Browse Now</a>
                </div>
                

                {/* <img className={"inline-block h-24"} src={antler} />
                <img className={"inline-block h-10"} src={intel} />
                <img className={"inline-block h-10"} src={aws} />
                <img className={"inline-block h-14"} src={tie} />
                <img className={"inline-block h-10"} src={campusfund} />
                <img className={"inline-block h-20"} src={paniit} />
                <img className={"inline-block h-20"} src={ecell} /> */}

            </div>
        </div>
  )
}

export default StoreBanner